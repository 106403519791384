import { Button, Col, Icon, Layout, Menu, message, Row } from 'antd';
import PropTypes from "prop-types";
import React, { Component } from 'react';
import { withRouter } from "react-router";
import { Route } from 'react-router-dom';

import ReactGA from 'react-ga';
import { GoogleLogin, GoogleLogout } from 'react-google-login';

import UrlPattern from 'url-pattern';

import { ReportsHomeByGathering, SecHome } from './dashboard';
import { Unauthorized } from './errors';
import { Login } from './form';
import { AddGatheringAttendee, CreateGathering, EditGathering, EditGatheringWeeknum, GatheringAttendees, Gatherings } from './gathering';
import { ReportsHome, ZoomHome } from './homes';
import {
  AttendanceReportByDateRange, AttendanceReportByGathering, AttendanceReportByGatheringAbsentees, IndividualAttendanceReport, ReportsHomeByDateRange
} from './reports';
import { ZoomLogs, ZoomAuditLogs } from './zoom';

import amsAPI from '../apis/amsAPI';

import 'antd/dist/antd.css';
import './ContentWrapper.css';

ReactGA.initialize('UA-146791855-1');

const {
  Header, Content,
} = Layout;

class SecWrapper extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props){
    super(props);
    this.state = {
      isLocaleMembersPage: false,
      isSignedIn: false,
      userInfo: {
        localeChurchId: '',
        memberId: '',
        roles: '',
        name: '',
        isAdmin: false,
        userMemberId: '',
      },
    };
    this.goBack = this.goBack.bind(this);
    this.goToNew = this.goToNew.bind(this);
    this.goHome = this.goHome.bind(this);
    this.onLoginSuccess = this.onLoginSuccess.bind(this);
    this.onLoginFailed = this.onLoginFailed.bind(this);
    this.onLogoutSuccess = this.onLogoutSuccess.bind(this);
 }

  goBack() {
    const pathname = this.props.location.pathname;
    const isListPage = pathname.substring(1).match(
        /^(stores|locale_churches|gatherings|church_groups|ministries|members)$/,
      );

    const pattern = new UrlPattern('/gatherings/:gatheringId/attendees');
    const isAttendeesPage = pattern.match(pathname);
    const linksPattern = new UrlPattern('/gatherings/:gatheringId/links');
    const isLinksPage = linksPattern.match(pathname);

    if (isListPage) {
      this.props.history.push("/");
    } else if (isAttendeesPage) {
      this.props.history.push("/gatherings");
    } else if (isLinksPage) {
      this.props.history.push("/gatherings");
    } else {
      this.props.history.goBack();
    }
  }

  goToNew() {
    const pathname = this.props.location.pathname;
    const newUrl = pathname + '/new';
    this.props.history.push(newUrl);
  }

  goHome() {
    this.props.history.push("/");
  }

  onLoginSuccess = info => {
    amsAPI.fetchUrl(`/ams/login/sec`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: info.profileObj.email,
      }),
    })
    .then(async res => {
      if (res.status === 200) {
        const body = await res.json();
        message.success("Successfully logged in.")
        this.setState({
          isSignedIn: true,
          localeChurchId: body.localeChurchId,
          userMemberId: body.userMemberId,
          groupName: body.groupName,
          userInfo: {
            localeChurchId: body.localeChurchId,
            memberId: body.userMemberId,
            groupName: body.groupName,
            roles: body.roles,
            userMemberId: body.userMemberId,
          },
          err: null
        })
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      message.error('Error logging in.');
    });
  }

  onLoginFailed = (err) => {
    console.log('login failed', err)
    this.setState({
      isSignedIn: false,
      error: err,
    })
  }

  onLogoutSuccess = () => {
    this.setState({
      isSignedIn: false,
    })
  }

  onLogoutFailed = (err) => {
    this.setState({
      isSignedIn: false,
      error: err,
    })
  }

  render() {
    const pathname = this.props.location.pathname;
    const isAdd = pathname.indexOf('new') >= 0;
    const attendeesPattern = new UrlPattern('/gatherings/:gatheringId/attendees');
    const isAttendeesPage = attendeesPattern.match(pathname);
    const displayAddButton = isAttendeesPage;
 
    const { isSignedIn } = this.state;
    //const isSignedIn = true;

    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Layout>
          <Header
            style={{
              position: 'fixed',
              zIndex: 1,
              width: '100%',
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Menu
              selectable={false}
              theme="dark"
              mode="horizontal"
              style={{ lineHeight: '20px' }}
            >
              <Menu.Item
                key="2"
                style={{ padding: '0' }}
              >
                {(pathname !== "/") &&
                  <Button
                    type="default"
                    size="large"
                    onClick={this.goHome}
                  >
                    <Icon type="home" />
                  </Button>
                }
                {(pathname !== "/" && isSignedIn) &&
                  <Button
                    type="default"
                    size="large"
                    onClick={this.goBack}
                    style={{ marginLeft: "5px" }}
                  >
                    Back
                  </Button>
                }
              </Menu.Item>
            </Menu>
            <Menu
              selectable={false}
              theme="dark"
              mode="horizontal"
              style={{ lineHeight: '20px' }}
            >
              <Menu.Item
                key="1"
              >
                {isSignedIn &&
                  <Button
                    type="default"
                    size="large"
                    disabled={isAdd}
                    onClick={this.goToNew}
                    style={
                      {
                        display: (displayAddButton) ? "inherit" : "none",
                        marginRight: '10px',
                      }
                    }
                  >Add</Button>
                }
                {(!isSignedIn && pathname !== "/") &&
                  <GoogleLogin
                    clientId={process.env.REACT_APP_CLIENT_ID}
                    buttonText="Login"
                    onSuccess={this.onLoginSuccess}
                    onFailure={this.onLoginFailed}
                    cookiePolicy={'single_host_origin'}
                  />
                }
                {isSignedIn &&
                  <GoogleLogout
                    clientId={process.env.REACT_APP_CLIENT_ID}
                    buttonText="Logout"
                    onLogoutSuccess={this.onLogoutSuccess}
                    onFailure={this.onLogoutFailed}
                  />
                }
              </Menu.Item>
            </Menu>
          </Header>
          <Content style={{ padding: '0 50px', marginTop: 75 }}>
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={24}>
                <div style={{ padding: 24, background: '#fff', minHeight: 320 }}>
                  <Route exact path="/"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <SecHome
                          {...props}
                          localeChurchId={this.state.localeChurchId}
                          groupName={this.state.groupName}
                          userInfo={this.state.userInfo}
                        />
                        :
                        <Login
                          onLoginSuccess={this.onLoginSuccess}
                          onLoginFailed={this.onLoginFailed}
                        />
                    }
                  />

                  <Route exact path="/reports"
                    render={(props) =>
                      this.state.isSignedIn ? <ReportsHome {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/reports/by_gathering"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <ReportsHomeByGathering
                          {...props}
                          localeChurchId={this.state.localeChurchId}
                          groupName={this.state.groupName}
                          userMemberId={this.state.userMemberId}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/reports/locale_attendance_by_gathering"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <AttendanceReportByGathering
                          {...props}
                          localeChurchId={this.state.localeChurchId}
                          userMemberId={this.state.userMemberId}
                          groupName={this.state.groupName}
                        />
                        :
                        <Unauthorized />
                    }
                  />
                  <Route exact path="/reports/locale_attendance_by_gathering_absentees"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <AttendanceReportByGatheringAbsentees
                          {...props}
                          localeChurchId={this.state.localeChurchId}
                          userMemberId={this.state.userMemberId}
                          groupName={this.state.groupName}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/reports/by_date_range"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <ReportsHomeByDateRange
                          {...props}
                          localeChurchId={this.state.localeChurchId}
                          userMemberId={this.state.userMemberId}
                          groupName={this.state.groupName}
                        />
                        :
                        <Unauthorized />
                    }
                  />
                  <Route exact path="/reports/locale_attendance_by_date_range"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <AttendanceReportByDateRange
                          {...props}
                          localeChurchId={this.state.localeChurchId}
                          userMemberId={this.state.userMemberId}
                          groupName={this.state.groupName}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/reports/individual"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <IndividualAttendanceReport
                          {...props}
                          localeChurchId={this.state.localeChurchId}
                          userMemberId={this.state.userMemberId}
                          groupName={this.state.groupName}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/gatherings"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Gatherings
                          {...props}
                          scope={"locale"}
                          userMemberId={this.state.userMemberId}
                          localeChurchId={this.state.localeChurchId}
                        />
                        :
                        <Unauthorized />
                    }
                  />
                  <Route exact path="/gatherings/new"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <CreateGathering
                          {...props}
                          userMemberId={this.state.userMemberId}
                          scope={"locale"}
                        />
                        :
                        <Unauthorized />
                    }
                  />
                  <Route exact path="/gatherings/:_id/edit"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <EditGathering
                          {...props}
                          userMemberId={this.state.userMemberId}
                          scope={"locale"}
                        />
                        :
                        <Unauthorized />
                    }
                  />
                  <Route exact path="/gatherings/:_id/edit_weeknum"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <EditGatheringWeeknum
                          {...props}
                          userMemberId={this.state.userMemberId}
                          scope={"locale"}
                        />
                        :
                        <Unauthorized />
                    }
                  />
                  <Route exact path="/gatherings/:gatheringId/attendees"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <GatheringAttendees
                          {...props}
                          scope={"locale"}
                          localeChurchId={this.state.localeChurchId}
                          userMemberId={this.state.userMemberId}
                          groupName={this.state.groupName}
                        /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/gatherings/:gatheringId/attendees/new"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <AddGatheringAttendee
                          {...props}
                          scope={"locale"}
                          localeChurchId={this.state.localeChurchId}
                          userMemberId={this.state.userMemberId}
                          groupName={this.state.groupName}
                        /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/zoom"
                    render={(props) =>
                      this.state.isSignedIn ? <ZoomHome {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/zoom/logs"
                    render={(props) =>
                      this.state.isSignedIn ? <ZoomLogs {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/zoom/audit_logs"
                    render={(props) =>
                      this.state.isSignedIn ? <ZoomAuditLogs {...props} /> : <Unauthorized />
                    }
                  />

                </div>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default withRouter(SecWrapper);
